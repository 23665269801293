





















import {  Vue } from 'vue-property-decorator';
import { roomService} from '../_services';
import EventBus  from '@/variables';
import router from '@/router';
import {IClient, RoomElement} from '@/types'
import { settings } from '@/variables';
import { set } from 'vue/types/umd';

export default Vue.extend({
    name: "DroppableCell",
    components: {
    },
    props: {
      reservation: Object,
    },
    data() {
      return {
        enabled: true,
        list2: [],
        customer: {} as IClient, // Object as PropType<CustomerData>,
        room: {} as RoomElement,
        resDate: "",
        isbooked: false,
        line1: '',
        line2: '',
        line3: '',
        eventHub: EventBus,
        setting: settings,
      }
    },
    methods: {
      onDrop(evt: any) {
        console.log("dropped");
        let customerData= evt.dataTransfer.getData("client");
        this.customer = JSON.parse(customerData);
        let doIt= true;
        if (this.customer.room!=null) {
          // check if move or extend
          if (this.room==this.customer.room) {
            if (evt.shiftKey) {
              doIt= false;
              console.log("extending reservation server side");
              this.customer.room= this.room; // initialize room for new clients
              this.reservation.clientKey= this.setting.dbschema+"_Client/"+this.customer._key;
              this.reservation.clientFirstName = this.customer.firstName;
              this.reservation.clientLastName = this.customer.lastName;
              this.reservation.clientColor = this.customer.clientColor;
              this.reservation.room= this.room;
              let data= {
                "_to": this.setting.dbschema+"_Room/"+this.reservation.room,
                "_from": this.reservation.clientKey,
                "resDate": this.customer.origDate,
                "createdAt": new Date(),
                "confirmSent": new Date(),
              }
              roomService.extendReservation(data, this.resDate).then((result: any) => {
                // router.push("/planning?startDay=" + Date.parse(this.customer.origDate));
                router.go(0);
              });
            }
          } else {
            doIt= false;
            let reservationKey= JSON.parse(evt.dataTransfer.getData("reservationKey"));
            console.log("move single reservation to other room server side ");
            console.log("moving to other room "+ reservationKey);
            if (evt.ctrlKey) {
              console.log("moving consecutive reservations to other room server side");
            } else {
              doIt= false;
              this.customer.room= this.room; // initialize room for new clients
              this.reservation.clientKey= this.setting.dbschema+"_Client/"+this.customer._key;
              this.reservation.clientFirstName = this.customer.firstName;
              this.reservation.clientLastName = this.customer.lastName;
              this.reservation.clientColor = this.customer.clientColor;
              this.reservation.room= this.room;
              let data= {
                "_key": reservationKey,
                "_to": this.setting.dbschema+"_Room/"+this.reservation.room,
                "_from": this.reservation.clientKey,
                "resDate": this.customer.origDate,
                "createdAt": new Date(),
                "confirmSent": new Date(),
              }
              roomService.updateReservation(data).then((result: any) => {
                // router.push("/planning?startDay=" + Date.parse(this.customer.origDate));
                router.go(0);
              });
            }
          }
        } else {
          // ignoring ctrl/shift keys in standard case // new booking!
        }
        if (doIt) {
          console.log("moving single reservation ")
          this.customer.room= this.room; // initialize room for new clients
          this.reservation.clientKey= this.setting.dbschema+"_Client/"+this.customer._key;
          this.reservation.clientFirstName = this.customer.firstName;
          this.reservation.clientLastName = this.customer.lastName;
          this.reservation.clientArtistName = this.customer.artistName;
          this.reservation.clientColor = this.customer.clientColor;
          this.reservation.date= this.resDate;
          this.reservation.room= this.room;
          let data= {
            "_to": this.setting.dbschema+"_Room/"+this.reservation.room,
            "_from": this.reservation.clientKey,
            "resDate": this.resDate,
            "createdAt": new Date(),
            "confirmSent": new Date(),
          }
          roomService.insertReservation(data);
          this.showReservation();
        }
      },
      startDrag(event: any, item: any) {
        console.log("drag started with client " + item._key + ", reservation " + this.reservation.key);
        item.origDate= this.resDate;
        event.dataTransfer.dropEffect= 'clone';
        event.dataTransfer.effectAllowed = 'clone';
        event.dataTransfer.setData("client", JSON.stringify(item));
        event.dataTransfer.setData("reservationKey", JSON.stringify(this.reservation.key));
      },
      showReservation() {
        if (this.reservation.clientKey!=null) {
          this.isbooked= true;
          this.line1= this.reservation.clientLastName.substring(0,5);
          this.line2= this.reservation.clientFirstName.substring(0,5);
          this.line3= this.reservation.clientArtistName.substring(0,5);
        } else {
          this.isbooked= false;
        }
      },
      selectCustomer() {
        this.eventHub.$emit("showChooseCustomerDialog", this.reservation);
      },
      reservationInfo():void {
        console.log("Click to droppable cell registered")
        this.eventHub.$emit('reservationInfo', this.reservation);
      },
    },
    mounted: function() {
      // this.$emit('reservationInfo');
    },
    created() {
      this.customer._key= this.reservation.clientKey;
      this.customer.firstName= this.reservation.clientFirstName;
      this.customer.lastName= this.reservation.clientLastName;
      this.customer.artistName= this.reservation.clientArtistName;
      this.customer.clientColor= this.reservation.clientColor;
      this.room= this.reservation.room;
      this.resDate= this.reservation.date;
      this.customer.room= this.reservation.room;
      this.showReservation();
    }
})
